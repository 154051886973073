<script setup>
useHead({ bodyAttrs: { id: 'admin' } })
</script>

<template>
  <div class="min-w-screen min-h-screen bg-slate-100">
    <slot></slot>

    <admin-ui-toast show-close position="bottom-right" />

    <admin-ui-loading />
  </div>
</template>